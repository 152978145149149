import  instagram from "./image/instagram.svg"
import  linkedin from "./image/linkedin.svg"
import  whatsapp from "./image/whatsapp.svg"
import  instagram2 from "./image/insta.png"
import  linkedin2 from "./image/linkedin.png"
import  whatsapp2 from "./image/wpp.png"
import image from "./image/CEO.jpg"
import { Fragment } from "react";

function Depoyment(props){

    return (

        <Fragment>
            <p className="montserrat text">{props.text}</p>
        </Fragment>

    )
}

// componente com a descrição do negócio da empresa e áreas de atuação
function ContactItem(props){


    return (

        <a className="ItemContato" target="_blank" href={props.link} rel="noreferrer">
            <div className="contentText">
                <h3 className="montserrat text">{props.title}</h3>
                <img src={props.image} className="iconImage" alt=""></img>
            </div>
        </a>
 
    )
   
    
}

// Componente contendo contato comercial e redes da empresa
function Contato(){

    return (
        <div id="CONTATO" className="Contato" >
            <hr className="divider"></hr>
            <h1 className="montserrat title">Contato</h1>
            <div className="contentList">
                {/* <div className="pessoal">
                    <h4 className="montserrat title">Como quer falar conosco?</h4>
                    <div className="personImage">
                        <img src={image} alt="CEO"></img>
                    </div>
                    <Depoyment author="Emanuel Joshua - Fundador" text='"Queremos te ouvir e apresentar uma solução especializada em você,
                    diferente de tudo que existe no mercado."'></Depoyment>
                </div> */}
                <div className="pages">
                    <ContactItem image={whatsapp} image2={whatsapp2} 
                    title="Whatsapp" link={"https://wa.link/q6sfpy"} />
                    <ContactItem image={instagram} image2={instagram2}  
                    title="Instagram" link={"https://www.instagram.com/speivox"}/>
                    <ContactItem image={linkedin}  image2={linkedin2}  
                    title="Linkedin" link={"https://www.linkedin.com/company/speivox-softwares"}/>
                </div>
            </div>
            <hr className="divider"></hr>
        </div>
    )

}

export {Contato}
