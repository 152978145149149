import video from "./video/movie.mp4"
import { Logo } from "./Logo";
import { Fragment } from "react";

function Header(){
    return (
    <Fragment>
        <header className="topBar">
            <Logo></Logo>
        </header>
        <div id="HEADER" className="header" 
        dangerouslySetInnerHTML={{ __html: `            
        <video autoPlay muted loop disablePictureInPicture playsInline >
                <source src=${video} type="video/mp4"/>
            </video>`}}
        />
    </Fragment>)

}

export {Header};